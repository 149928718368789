import React, { Suspense } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { GlobalStateProvider, AuthProvider } from "src/contexts";
import "src/scss/style.scss";
import { useAuth } from "./hooks/useAuth";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("src/layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("src/pages/Login"));
// const Register = React.lazy(() => import("src/pages/Register"));
const Page404 = React.lazy(() => import("src/pages/Page404"));
const Page500 = React.lazy(() => import("src/pages/Page500"));

const AppRoutes = () => {
  const { auth } = useAuth();

  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route path="/login" name="Login Page" element={auth?.user === "Authenticated" ? <Navigate to="/" /> : <Login />} />
        {/* <Route path="/register" name="Register Page" element={auth.user === "Authenticated" ? <Navigate to="/" /> : <Register />} /> */}
        <Route path="/404" name="Page 404" element={<Page404 />} />
        <Route path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Home" element={auth?.user === "Authenticated" ? <DefaultLayout /> : <Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <GlobalStateProvider>
        <HashRouter>
          <AppRoutes />
        </HashRouter>
      </GlobalStateProvider>
    </AuthProvider>
  );
};

export default App;
